













import { Component, Vue } from 'vue-property-decorator'

@Component({ })
export default class Login extends Vue {
  private accountImg = '';
  private discordOauthUrl = '';

  created () {
    this.accountImg = 'https://cdn.discordapp.com/attachments/713146013782639120/719863539434651699/NATIONs.png'
    this.discordOauthUrl = 'https://discord.com/api/oauth2/authorize?prompt=none&client_id=765754752147128331&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth%2Fdiscord&response_type=code&scope=identify%20email%20guilds%20guilds.join'
  }
}
